<template>
  <div>
    <b-form class="p-1">
      <b-form ref="form">
        <b-row>
          <b-col md="6">
            <label for="input-group-2">
              Employee Name <span class="text-danger">*</span></label
            >
            <b-form-group
              id="input-group-2"
              :state="employeeState"
              invalid-feedback="Employee is required"
            >
              <v-select
                v-model="form.employeeName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="employees"
                class="bg-light"
                placeholder="Select Employee"
                @input="setemployeeId(form)"
                :disabled="$route.params.id ? true : false"
                :state="employeeState"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="input-group-3">
              Employee Id <span class="text-danger">*</span></label
            >
            <b-form-group
              id="input-group-3"
              :state="employeeState"
              invalid-feedback="Employee id is required"
            >
              <b-form-input
                id="input-2"
                v-model="form.user_id"
                placeholder="Will be auto filled according to employee name"
                required
                class="bg-light data-text"
                disabled
                :state="employeeState"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="input-group-4">
              <span v-if="logedInUser.roles[0].name == 'employee'">
                Company Name</span
              >
              <span v-else>Assigned By</span>
              <span class="text-danger"> *</span></label
            >
            <b-form-group
              id="input-group-4"
              :state="userState"
              invalid-feedback="Assigned by is required"
            >
              <v-select
                v-model="form.assignBy"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="companies"
                class="bg-light"
                placeholder="Select Assigned By(Company)"
                :disabled="$route.params.id ? true : false"
                :state="userState"
                @input="checkFormValidity"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="input-group-5">
              <span v-if="logedInUser.roles[0].name == 'employee'">
                Client Name</span
              >
              <span v-else>Assigned To</span>
              <span class="text-danger">*</span></label
            >
            <b-form-group
              id="input-group-5"
              :state="customerState"
              invalid-feedback="Assigned to is required"
            >
              <v-select
                v-model="form.assignTo"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="assignTo"
                class="bg-light"
                placeholder="Select Assigned To"
                :disabled="$route.params.id ? true : false"
                :state="customerState"
                @input="getShifts(form.assignTo)"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <label for="input-group-6">
              Assigned Job Role <span class="text-danger">*</span></label
            >
            <b-form-group
              id="input-group-6"
              :state="dutyState"
              invalid-feedback="Job Role is required"
            >
              <v-select
                v-model="form.duty"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="duties"
                class="bg-light"
                placeholder="Select Job Role"
                :disabled="$route.params.id ? true : false"
                :state="dutyState"
                @input="checkFormValidity"
              />
              <!-- :disabled="roster.status == 1" -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="input-group-6">
              Assigned Shift <span class="text-danger">*</span></label
            >
            <b-form-group
              id="input-group-6"
              :state="shiftState"
              invalid-feedback="Shift is required"
            >
              <v-select
                v-model="form.shift"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="shifts"
                class="bg-light"
                placeholder="Select Shift"
                :disabled="$route.params.id ? true : false"
                :state="shiftState"
                @input="selectShift(form.shift)"
              />
              <!-- :disabled="roster.status == 1" -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label for="input-group-6"> Change Status</label>
            <b-form-group id="input-group-6">
              <v-select
                v-model="form.state_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="statuses"
                class="bg-light"
                placeholder="Select Status"
              />
              <!-- :disabled="roster.status == 1" -->
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="$route.params.id">
            <b-row>
              <b-col md="4">
                <b-form-group id="input-group-2" label="Date" label-for="Date">
                  <b-form-datepicker
                    id="date"
                    v-model="form.date"
                    class="mb-2 bg-light"
                    date-format="DD-MM-YYYY"
                    :disabled="$route.params.id ? true : false"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="input-group-2"
                  label="In Time"
                  label-for="In Time"
                >
                  <b-form-timepicker
                    class="bg-light"
                    v-model="form.in_time"
                    locale="en"
                    now-button
                    label-close-button="Set"
                  ></b-form-timepicker>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="input-group-2"
                  label="Out Time"
                  label-for="Out Time"
                >
                  <b-form-timepicker
                    class="bg-light"
                    v-model="form.out_time"
                    locale="en"
                    now-button
                    label-close-button="Set"
                  ></b-form-timepicker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" v-else>
            <b-row v-for="(time, index) in form.times" class="" :key="index">
              <b-col md="4">
                <label :for="`date-${index}`">
                  Date <span class="text-danger">*</span></label
                >
                <b-form-group
                  :state="time.dayState"
                  invalid-feedback="Date is required"
                >
                  <b-form-datepicker
                    :id="`date-${index}`"
                    v-model="time.date"
                    class="bg-light"
                    date-format="DD-MM-YYYY"
                    @input="checkDate(time, index)"
                    :state="time.dayState"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <label :for="`in-${index}`">
                  In Time <span class="text-danger">*</span></label
                >
                <b-form-group
                  :state="time.startState"
                  invalid-feedback="Start time is required"
                >
                  <b-form-timepicker
                    :id="`in-${index}`"
                    class="bg-light"
                    v-model="time.in_time"
                    locale="en"
                    :state="time.startState"
                    @input="checkFormValidity"
                    now-button
                    label-close-button="Set"
                  ></b-form-timepicker>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <label :for="`out-${index}`">
                  Out Time <span class="text-danger">*</span></label
                >
                <b-form-group
                  :state="time.endState"
                  invalid-feedback="End time is required"
                >
                  <b-form-timepicker
                    :id="`out-${index}`"
                    class="bg-light"
                    v-model="time.out_time"
                    locale="en"
                    :state="time.endState"
                    @input="checkFormValidity"
                    now-button
                    label-close-button="Set"
                  ></b-form-timepicker>
                </b-form-group>
              </b-col>
              <b-col class="d-flex align-items-center" md="2">
                <button
                  class="btn btn-danger btn-sm mr-1"
                  @click.prevent="removeTime(index)"
                  v-if="index > 0"
                >
                  x
                </button>
                <button
                  class="btn btn-success btn-sm"
                  @click.prevent="addTime"
                  v-if="form.times.length - 1 == index"
                >
                  +
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="mt-4">
        <b-col class="d-flex">
          <router-link to="/time-sheets">
            <b-button class="mx-1 px-4 btn-danger">
              <span v-if="$route.params.id ? true : false">Back</span>
              <span v-else>Cancel</span>
            </b-button>
          </router-link>
          <b-button
            v-if="$route.params.id ? true : false"
            @click.prevent="handleUpdate"
            class="mx-1 px-4 send-btn"
          >
            Publish
          </b-button>
          <b-button
            v-else
            @click.prevent="handleCreate"
            class="mx-1 px-4 send-btn"
          >
            Publish
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import roster from "@/store/roster";
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormTimepicker,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    vSelect,
    BSpinner,
    BFormTimepicker,
  },
  props: {
    timesheet: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      duties: [],
      shifts: [],
      loading: false,
      logedInUser: JSON.parse(getLogedInUser()),
      form: {
        default: {},
        user_id: "",
        employee_id: "",
        customer_id: "",
        duty_id: "",
        employeeName: "",
        date: "",
        in_time: "",
        out_time: "",
        duration: "",
        duty: "",
        shift: "",
        assignTo: "",
        assignBy: "",
        times: [
          {
            in_time: "",
            out_time: "",
            date: "",
            startState: null,
            endState: null,
            dayState: null,
          },
        ],
      },
      statuses: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Approve" },
        { id: 2, name: "Decline" },
        { id: 3, name: "Verify" },
      ],
      employee: [],
      employees: [],
      total: 0,
      customers: [],
      companies: [],
      assignTo: [],
      query: {
        page: 1,
        per_page: 1000,
        company_id: "",
      },
      userState: null,
      customerState: null,
      employeeState: null,
      dutyState: null,
      shiftState: null,
    };
  },

  mounted() {
    this.getDuties();
    if (this.$route.params.id) {
      this.form = this.timesheet;
      this.form.in_time = moment(this.timesheet.in_time).format("HH:mm");
      this.form.out_time = moment(this.timesheet.out_time).format("HH:mm");
      this.form.assignBy = this.timesheet.roster?.user;
      this.form.assignTo = this.timesheet.roster?.customer;
    }
    if (this.logedInUser.roles[0].name === "admin") {
      this.form.assignBy = this.logedInUser;
    }
    if (this.logedInUser.roles[0].name === "customer") {
      this.form.assignTo = this.logedInUser;
      this.getShifts(this.form.assignTo);
    }
    if (this.logedInUser.roles[0].name === "employee") {
      this.form.employeeName = this.logedInUser;
      this.form.user_id = this.logedInUser.id;
      if (
        this.logedInUser.employee_to_company &&
        this.logedInUser.employee_to_company.length > 0
      ) {
        this.form.assignBy = this.logedInUser.employee_to_company[0];
        this.companies = this.logedInUser.employee_to_company;
      }
      if (
        this.logedInUser.employee_customer &&
        this.logedInUser.employee_customer.length > 0
      ) {
        this.form.assignTo = this.logedInUser.employee_customer[0];
        this.assignTo = this.logedInUser.employee_customer;
        this.getShifts(this.form.assignTo);
      }
    } else {
      this.getEmployees();
    }
  },
  methods: {
    getEmployee(e) {
      this.employee = getLogedInUser();
    },
    async getShifts(customer) {
      let res = await this.$store.dispatch(
        "shift/getClientShifts",
        customer.id
      );
      this.shifts = res.data;
      if (this.shifts.length === 1) {
        this.form.shift = this.shifts[0];
        this.selectShift(this.form.shift);
      }
      this.checkFormValidity();
    },
    selectShift(shift) {
      this.form.times.forEach((time) => {
        time.in_time = shift.start_time;
        time.out_time = shift.end_time;
      });
      this.checkFormValidity();
    },

    addTime() {
      this.form.times.push({
        in_time: "",
        out_time: "",
        date: "",
        startState: null,
        endState: null,
        dayState: null,
      });
    },
    removeTime(index) {
      this.form.times.splice(index, 1);
    },
    checkDate(time, index) {
      // if date is already exist
      if (this.form.times.find((x) => x.date == time.date && x != time)) {
        this.form.times[index].date = "";
        time.date = "";
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Date is already exist!"
        );
      }
      this.checkFormValidity();
    },
    getEmployees(e) {
      if (this.logedInUser.roles[0].name == "admin") {
        // this.query.company_id = this.logedInUser.id;
        let roles = this.logedInUser.roles.map((role) => {
          return role.name;
        });
        if (roles.includes("supervisor")) {
          this.query.company_id = this.logedInUser.supervisor.company_id;
          this.query.user_id = this.logedInUser.supervisor.company_id;
        } else {
          this.query.user_id = this.logedInUser.id;
          this.query.company_id = this.logedInUser.id;
        }
      }
      if (this.logedInUser.roles[0].name == "customer") {
        this.query.customer_id = this.logedInUser.id;
      }
      if (e) {
        this.query.page = e;
      }
      this.$store
        .dispatch("employee/getEmployees", this.query)
        .then((res) => {
          this.employees = res.data.data;
          if (this.$route.params.id) {
            this.form.employeeName = this.timesheet?.roster?.employee;
            this.form.user_id = this.timesheet.employee_id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setemployeeId(data) {
      this.form.user_id = data.employeeName.id;
      this.assignTo = data.employeeName.employee_customer;
      if (this.logedInUser.roles[0].name !== "customer") {
        if (this.assignTo.length > 0) {
          this.form.assignTo = this.assignTo[0];
          this.getShifts(this.form.assignTo);
        }
      }
      this.companies = data.employeeName.employee_to_company;
      if (this.logedInUser.roles[0].name !== "admin") {
        if (this.companies.length > 0) {
          this.form.assignBy = this.companies[0];
        }
      }
      this.checkFormValidity();
    },
    async getDuties() {
      let res = await this.$store.dispatch("duty/getDuties");
      this.duties = res.data.data;
      if (this.$route.params.id && this.duties.length > 0) {
        this.form.duty = this.duties.find(
          (duty) => duty.id == this.timesheet?.roster?.details[0]?.duty
        );
      }

      if (this.timesheet && this.timesheet.id) {
        if (this.timesheet.status === 1 && this.timesheet.verify === 1) {
          this.timesheet.state_status = this.statuses.find(
            (status) => status.id == 3
          );
        } else {
          this.timesheet.state_status = this.statuses.find(
            (status) => status.id == this.timesheet.status
          );
        }
      }
    },
    handleUpdate() {
      let data = {
        id: this.$route.params.id,
        date: this.form.date,
        in_time: this.form.date + " " + this.form.in_time,
        out_time: this.form.date + " " + this.form.out_time,
        verify: this.form.state_status.id == 3 ? 1 : 0,
        status: this.form.state_status.id == 3 ? 1 : this.form.state_status.id,
      };
      this.$store
        .dispatch("roster/rosterWorkApprove", data)
        .then(({ data }) => {
          this.$router.push("/time-sheets");
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Timesheet updated Successfully."
          );
        })
        .catch((err) => {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            err.response?.data?.message || "Something went wrong"
          );
        });
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      if (this.form.assignBy !== "") {
        valid = true;
        this.userState = valid;
      } else {
        valid = false;
        this.userState = valid;
      }
      if (this.form.assignTo !== "") {
        valid = true;
        this.customerState = valid;
      } else {
        valid = false;
        this.customerState = valid;
      }
      if (this.form.employeeName !== "") {
        valid = true;
        this.employeeState = valid;
      } else {
        valid = false;
        this.employeeState = valid;
      }
      if (this.form.duty !== "") {
        valid = true;
        this.dutyState = valid;
      } else {
        valid = false;
        this.dutyState = valid;
      }
      if (this.form.shift !== "") {
        valid = true;
        this.shiftState = valid;
      } else {
        valid = false;
        this.shiftState = valid;
      }

      if (this.form.times.length > 0) {
        this.form.times.forEach((d) => {
          if (d.date == "" || d.date == null) {
            valid = false;
            d.dayState = valid;
          } else {
            valid = true;
            d.dayState = valid;
          }
          if (d.in_time == "" || d.in_time == null) {
            valid = false;
            d.startState = valid;
          } else {
            valid = true;
            d.startState = valid;
          }
          if (d.out_time == "" || d.out_time == null) {
            valid = false;
            d.endState = valid;
          } else {
            valid = true;
            d.endState = valid;
          }
        });
      }
      return valid;
    },
    handleCreate() {
      if (!this.checkFormValidity()) {
        return;
      }
      let data = {
        times: [],
        verify: 0,
        status: 0,
        employee_id: this.form.user_id,
        customer_id: this.form.assignTo.id,
        user_id: this.form.assignBy.id,
        duty_id: this.form.duty.id,
        shift_id: this.form.shift.id,
      };
      if (this.form.times.length > 0) {
        this.form.times.forEach((time) => {
          data.times.push({
            date: time.date,
            in_time: time.date + " " + time.in_time,
            out_time: time.date + " " + time.out_time,
          });
        });
      }
      this.$store
        .dispatch("roster/customCreateTimesheet", data)
        .then((res) => {
          // this.$router.push("/time-sheets");
          if (res.status_code == 200 && res.success > 0) {
            this.$router.push("/time-sheets");
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              `Total ${res.success} Timesheet has been created Successfully.`
            );
          } else {
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              res.message ||
                "Something went wrong. Maybe employee not accepted the roster."
            );
          }
        })
        .catch((err) => {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            err.response?.data?.message || "Something went wrong"
          );
        });
    },
  },
};
</script>

<style scoped>
.btn-space {
  margin: 2px;
}
.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}
.decline {
  background-color: #ff4f5d1a !important;
  color: #ff4f5d !important;
  border-color: transparent !important;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
}
.send-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);

  border-color: transparent !important;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}
.preview {
  border-color: transparent !important;
  background: linear-gradient(315deg, white 100%, white 100%, white 100%);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
