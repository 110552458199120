<template>
  <div class="card">
    <form-component v-if="timesheet.id" :timesheet="timesheet" />
  </div>
</template>

<script>
import FormComponent from "./components/FormComponent.vue";
export default {
  components: {
    FormComponent,
  },
  data() {
    return {
      timesheet: {
        id: "",
        employee_id: "",
        date: "",
        start_time: "",
        end_time: "",
        break_time: "",
        total_hours: "",
        duty_id: "",
        shift_id: "",
        notes: "",
        status: "",
        verify: "",
      },
    };
  },
  created() {
    this.getTimesheet();
  },
  methods: {
    getTimesheet() {
      this.loading = true;
      this.$store
        .dispatch("roster/getTimesheet", this.$route.params.id)
        .then((res) => {
          this.timesheet = res.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>